import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    CreateUpdateRiskAdvisoryRequest,
    RiskAdvisory,
    RiskAdvisoryType,
    RiskAdvisoryTypeLabels,
} from '@entities/risk-advisory';
import { FormUtilsService } from '../../utils/form-utils.service';
import { ArtifactOwnershipLevel } from '@entities/artifact';
import { Store } from '@ngrx/store';
import {
    createVendorRiskAdvisoryRequest,
    createVendorRiskAdvisoryRequestSuccess,
    summarizeRiskAdvisoryLink,
    summarizeRiskAdvisoryLinkRequestFailed,
    summarizeRiskAdvisoryLinkRequestSuccess,
    updateVendorRiskAdvisoryRequest,
    updateVendorRiskAdvisoryRequestSuccess,
} from './redux/actions/vendor-risk-advisory.actions';
import { MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { urlValidator } from '../../validators/url-validator';

interface RiskAdvisoryFormGroup {
    id: FormControl<string>;
    orgId: FormControl<number>;
    type: FormControl<RiskAdvisoryType>;
    url: FormControl<string>;
    title: FormControl<string>;
    description: FormControl<string>;
    publishedDate: FormControl<Date>;
    material: FormControl<boolean>;
}

@Component({
    selector: 'app-vendor-risk-advisory-dialog',
    templateUrl: './vendor-risk-advisory-dialog.component.html',
    styleUrls: ['./vendor-risk-advisory-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorRiskAdvisoryDialogComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    orgId!: number;

    @Input({ required: true })
    name!: string;

    @Input()
    riskAdvisory: RiskAdvisory;

    @Input()
    isEditing: boolean;

    isSummarizing: boolean;
    showMaterialCheckbox$: Observable<boolean>;

    riskAdvisoryFormGroup: FormGroup<RiskAdvisoryFormGroup>;

    RiskAdvisoryType = RiskAdvisoryType;
    RiskAdvisoryTypeLabels = RiskAdvisoryTypeLabels;

    maxDateAllowed: Date = new Date();

    private _unsub$ = new Subject<void>();

    constructor(
        private _fb: FormBuilder,
        private _formUtils: FormUtilsService,
        private _store$: Store,
        private _actions$: Actions,
        public _dialogRef: MatDialogRef<VendorRiskAdvisoryDialogComponent>,
    ) {}

    ngOnInit(): void {
        this.riskAdvisoryFormGroup = this._fb.group({
            id: this._fb.control(this.riskAdvisory?.id),
            orgId: this._fb.control(this.riskAdvisory?.orgId || this.orgId, {
                validators: Validators.required,
                nonNullable: true,
            }),
            type: this._fb.control(this.riskAdvisory?.type, {
                validators: Validators.required,
                nonNullable: true,
            }),
            url: this._fb.control(this.riskAdvisory?.url, {
                validators: [Validators.required, urlValidator],
                nonNullable: true,
            }),
            title: this._fb.control(this.riskAdvisory?.title, {
                validators: [Validators.required, Validators.maxLength(125)],
                nonNullable: true,
            }),
            description: this._fb.control(this.riskAdvisory?.description),
            publishedDate: this._fb.control(this.riskAdvisory?.publishedDate, {
                validators: Validators.required,
                nonNullable: true,
            }),
            material: this._fb.control(this.riskAdvisory?.material),
        });

        this.showMaterialCheckbox$ = this.riskAdvisoryFormGroup.controls.type.valueChanges.pipe(
            map((riskAdvisoryType: RiskAdvisoryType) => riskAdvisoryType === RiskAdvisoryType.SECURITY),
            startWith(false),
        );

        this._actions$
            .pipe(
                ofType(createVendorRiskAdvisoryRequestSuccess, updateVendorRiskAdvisoryRequestSuccess),
                takeUntil(this._unsub$),
            )
            .subscribe(() => {
                this._dialogRef.close();
            });
        this._actions$
            .pipe(ofType(summarizeRiskAdvisoryLinkRequestSuccess), takeUntil(this._unsub$))
            .subscribe(({ summary }): void => {
                this.riskAdvisoryFormGroup.patchValue({
                    title: summary.title ?? this.riskAdvisoryFormGroup.controls.title.value,
                    publishedDate: !!summary.date
                        ? new Date(summary.date)
                        : this.riskAdvisoryFormGroup.controls.publishedDate.value,
                    description: summary.summary ?? this.riskAdvisoryFormGroup.controls.description.value,
                });
                this.isSummarizing = false;
            });
        this._actions$
            .pipe(ofType(summarizeRiskAdvisoryLinkRequestFailed), takeUntil(this._unsub$))
            .subscribe((): void => {
                this.isSummarizing = false;
            });
    }

    createRiskAdvisory(): void {
        this._store$.dispatch(
            createVendorRiskAdvisoryRequest({ request: this.getCreateUpdateRiskAdvisoryPayload(), name: this.name }),
        );
    }

    editRiskAdvisory(): void {
        this._store$.dispatch(
            updateVendorRiskAdvisoryRequest({ request: this.getCreateUpdateRiskAdvisoryPayload(), name: this.name }),
        );
    }

    summarizeRiskAdvisoryLink(): void {
        this.isSummarizing = true;
        this._store$.dispatch(summarizeRiskAdvisoryLink({ url: this.getUrl() }));
    }

    private getCreateUpdateRiskAdvisoryPayload(): CreateUpdateRiskAdvisoryRequest {
        const { id, orgId, type, url, title, description, publishedDate, material } =
            this._formUtils.getCleanTypedFormGroupValue<FormGroup<RiskAdvisoryFormGroup>>(
                this.riskAdvisoryFormGroup,
                true,
            );

        return {
            id,
            orgId,
            type,
            title,
            description,
            publishedDate,
            material,
            urlArtifactSaveRequest: {
                url,
                metaData: {
                    artifactOwnershipType: ArtifactOwnershipLevel.PUBLIC,
                },
            },
        };
    }

    private getUrl(): string {
        const { url } = this._formUtils.getCleanTypedFormGroupValue<FormGroup<RiskAdvisoryFormGroup>>(
            this.riskAdvisoryFormGroup,
            true,
        );
        return url;
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
