import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type visoChipType =
    | 'success'
    | 'danger'
    | 'basic'
    | 'info'
    | 'basic-outline'
    | 'danger-outline'
    | 'info-outline'
    | 'small-info-outline';

@Component({
    selector: 'app-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
    @Input({ required: true })
    type: visoChipType;

    @Input()
    condensed: boolean = false;

    @Input()
    disableRipple: boolean = false;

    get chipTypeClass(): string {
        return `chip-${this.type} ${this.condensed ? 'condensed' : ''}`;
    }
}
