<div class="row mb-4">
    <div class="col">
        <div class="justify-content-between d-flex">
            <div>
                <h3 class="lead mb-2">Details</h3>
                <p>Last updated {{ updatedDate | timeAgo }}</p>
            </div>
            <div>
                <button
                    class="me-2"
                    *jhiHasAnyAuthority="[Roles.Auditor, Roles.Support, Roles.Admin]"
                    mat-button
                    visoButton="primary"
                    (click)="onEditClicked.emit()"
                >
                    Edit
                </button>
                <ng-container *ngIf="showRefreshDetailsButton">
                    <button
                        *jhiHasAnyAuthority="[Roles.Auditor, Roles.Support, Roles.Admin]"
                        mat-button
                        visoButton="secondary"
                        (click)="onRefreshDetailsClicked.emit()"
                        toggleDisableOnActions
                        [disabled]="refreshingVendorDetails"
                    >
                        {{ refreshingVendorDetails ? 'Refreshing...' : 'Refresh details' }}
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-8">
        <div class="row mb-4">
            <div class="col">
                <div class="row">
                    <div class="col description-field">
                        <h4 class="mb-2">Description</h4>
                        <p>{{ !!description ? description : '-' }}</p>
                    </div>
                    <div class="col">
                        <h4 class="mb-2">Founded</h4>
                        <p>{{ !!foundedDate ? (foundedDate | date: 'yyyy') : '-' }}</p>
                    </div>
                    <div class="col">
                        <h4 class="mb-2">Employees</h4>
                        <p>{{ !!companySize ? companySize : '-' }}</p>
                    </div>
                    <div class="col word-wrap">
                        <h4 class="mb-2">Industry</h4>
                        <p>{{ !!industry ? industry : '-' }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-4" *ngIf="vendorDetails?.complianceStandards?.length > 0">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <h4 class="mb-2">Compliance</h4>
                        <div class="compliance-container">
                            <mat-card
                                class="outlined-card d-flex flex-row justify-content-between align-items-center"
                                [appearance]="'outlined'"
                                *ngFor="let standard of complianceStandards"
                            >
                                <mat-card-content class="pt-0">
                                    <h4>{{ ComplianceStandardLabels[standard] }}</h4>
                                </mat-card-content>
                                <img
                                    mat-card-image
                                    [ngClass]="{
                                        'fedramp-badge': standard === ComplianceStandard.FEDRAMP,
                                        'hitrust-badge': standard === ComplianceStandard.HITRUST,
                                        'ccpa-badge': standard === ComplianceStandard.CCPA,
                                        'compliance-badge': !complianceStandardsFullSize.includes(standard),
                                        'compliance-full-size-badge': complianceStandardsFullSize.includes(standard),
                                    }"
                                    class="compliance-icon"
                                    [appImageFallback]="badgeImageFallback"
                                    onImageErrorCssClass="no-compliance-badge"
                                    [src]="standard | callback: getComplianceIcon"
                                    alt="Compliance Badge"
                                    height="80"
                                    width="80"
                                />
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-4" *ngIf="leadership?.length > 0">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <h4 class="mb-2">Security Leadership</h4>
                        <mat-list>
                            <mat-list-item *ngFor="let leader of leadership" class="mb-2">
                                <span matListItemTitle class="text-sm">{{ leader.title }}</span>
                                <span matListItemLine class="text-dark">{{
                                    leader.firstName + ' ' + leader.lastName
                                }}</span>
                                <span matListItemLine class="text-dark">{{
                                    leader.email || 'No email address found'
                                }}</span>
                                <div matListItemMeta>
                                    <a
                                        class="fab-outlined"
                                        mat-fab
                                        extended
                                        [externalLink]="leader.linkedinUrl"
                                        target="_blank"
                                    >
                                        <img
                                            class="url-button-icon"
                                            src="content/svg/linkedin.svg"
                                            alt="LinkedIn Icon"
                                        />
                                        View profile
                                    </a>
                                </div>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="showRiskAdvisories">
            <div class="row">
                <div class="col">
                    <h4 class="mb-2">Risk advisories</h4>
                    <ng-container *ngIf="riskAdvisories?.length > 0; else noRiskAdvisories">
                        <p>View details for each advisory to understand potential impact to your threat surface.</p>
                        <app-vendor-risk-advisory-table
                            class="d-block mt-4"
                            [data]="riskAdvisories"
                            [totalRiskAdvisories]="totalRiskAdvisories"
                            [canEdit]="userCanEdit"
                            (pageChange)="onRiskAdvisoryPageChange.emit($event)"
                            (sortChange)="onRiskAdvisorySortChange.emit($event)"
                            (deleteRiskAdvisory)="deleteRiskAdvisory.emit($event)"
                        />
                    </ng-container>
                    <ng-template #noRiskAdvisories>
                        <p class="text-muted">
                            No risk advisories for this vendor, yet. If VISO TRUST discovers relevant risk information,
                            it will appear both here and in your risk assessment.
                        </p>
                    </ng-template>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="col-auto">
        <mat-divider class="vertical-divider" [vertical]="true" />
    </div>
    <div class="col-3">
        <div class="row mb-4">
            <div class="col">
                <h4 class="mb-2">Filing Status</h4>
                <app-chip [disableRipple]="true" type="basic-outline">
                    <div class="d-flex">
                        <div class="mt-1 me-2">
                            <mat-icon class="text-secondary" *ngIf="isPubliclyTraded">public</mat-icon>
                            <mat-icon class="text-secondary" *ngIf="!isPubliclyTraded">vpn_lock</mat-icon>
                        </div>
                        <div class="align-self-center">
                            <span>{{ isPubliclyTraded ? 'Public' : 'Private' }}</span>
                        </div>
                    </div>
                </app-chip>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col">
                <h4 class="mb-2">Links</h4>
                @for (link of links$ | async; track $index) {
                    <div class="mt-3">
                        <p class="text-muted fw-bold mb-0">{{ link.name }}</p>
                        <a
                            [ngClass]="{ 'text-decoration-underline': !!link.url }"
                            [externalLink]="link.url"
                            target="_blank"
                            appMiddleTruncate
                            [truncateShowTooltip]="true"
                        >
                            {{ !!link.url ? stripLinkProtocol(link.url) : '-' }}
                        </a>
                        @if (link.name === trustCenterLinkName && urlIsFromSafeBase()) {
                            <div class="safebase-disclaimer d-flex align-items-center">
                                <span>In partnership with <strong>SafeBase</strong></span>
                                <mat-icon class="ms-2" svgIcon="safebase" [inline]="true"></mat-icon>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    </div>
</div>
