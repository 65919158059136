import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VisoUser, VisoUserRole } from '../../../../entities/viso-user';

interface SubscriberFullName {
    fullname: string;
}

@Component({
    selector: 'app-subscribers',
    templateUrl: './subscribers.component.html',
    styleUrls: ['./subscribers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribersComponent {
    @Input()
    set subscribers(value: VisoUser[]) {
        const defaultSubscribers = value.filter((subscriber) => subscriber.defaultSubscriber);
        const nonDefaultSubscribers = value.filter((subscriber) => !subscriber.defaultSubscriber);

        const sortByFullname = (a: VisoUser, b: VisoUser) => {
            const fullnameA = `${a.firstName} ${a.lastName}`.toLowerCase();
            const fullnameB = `${b.firstName} ${b.lastName}`.toLowerCase();
            return fullnameA.localeCompare(fullnameB);
        };
        defaultSubscribers.sort(sortByFullname);
        nonDefaultSubscribers.sort(sortByFullname);

        const allSubscribers = [];
        for (const subscriber of defaultSubscribers) {
            allSubscribers.push({
                ...subscriber,
                fullname: `${subscriber.firstName} ${subscriber.lastName}`,
            });
        }
        for (const subscriber of nonDefaultSubscribers) {
            allSubscribers.push({
                ...subscriber,
                fullname: `${subscriber.firstName} ${subscriber.lastName}`,
            });
        }

        this.relationshipSubscribers$.next(allSubscribers);
    }

    @Input()
    export = false;

    @Input()
    relationshipId: number;

    @Output()
    unsubscribeSubscriber = new EventEmitter<number>();

    relationshipSubscribers$ = new BehaviorSubject<(VisoUser & SubscriberFullName)[]>([]);

    Roles = VisoUserRole;

    get addLink() {
        return [
            '/',
            {
                outlets: {
                    popup: `relationships/${this.relationshipId}/subscriber`,
                },
            },
        ];
    }

    get subscriberListExists(): boolean {
        return !!this.relationshipSubscribers$.value?.length;
    }

    hoveredSubscriberId: string | null = null;

    isHovered(subscriberId: string): boolean {
        return this.hoveredSubscriberId === subscriberId;
    }
    onMouseEnter(subscriberId: string): void {
        this.hoveredSubscriberId = subscriberId;
    }
    onMouseLeave(): void {
        this.hoveredSubscriberId = null;
    }
}
