import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStatus, VisoUser, VisoUserRole } from '../../entities/viso-user';
import { Actions, ofType } from '@ngrx/effects';
import {
    createContactRequestSuccess,
    deleteContactRequestSuccess,
    getContactsRequest,
    getContactsRequestFailed,
    getContactsRequestSuccess,
    openDeleteContactConfirmationModal,
    openUpdateContactStatusConfirmationModal,
    updateManagedContactStatusRequestSuccess,
    reassignContactRelationshipsRequestSuccess,
    updateContactRequestSuccess,
} from './redux/contact-management.actions';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../shared/redux/state';
import { HttpHeaders } from '@angular/common/http';
import { BREADCRUMB_CONTAINER_TOKEN } from '../../shared/dynamic-content/dynamic-content-injector';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';

interface TableSortItem {
    prop: string;
    dir: string;
}

@Component({
    selector: 'app-contact-management',
    templateUrl: './contact-management.component.html',
    styleUrls: ['./contact-management.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactManagementComponent implements OnInit, OnDestroy {
    public readonly breadcrumbsContainerToken = BREADCRUMB_CONTAINER_TOKEN;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    Roles = VisoUserRole;
    UserStatus = UserStatus;
    sortByColumnName: string = 'name';
    sortDirection: SortDirection = 'asc';
    contactsLoading: boolean;
    resultsLength = 0;

    itemsPerPage: any;
    totalCurrentItems: number;
    page: number;
    predicate: any;
    previousPage: any;
    reverse: any;

    tableSort$: BehaviorSubject<TableSortItem[]> = new BehaviorSubject<TableSortItem[]>([{ prop: 'name', dir: 'asc' }]);

    displayedColumns = [
        'name',
        'email',
        'businessUnit',
        'defaultSubscriber',
        'ownerships',
        'subscriptions',
        'role',
        'actions',
    ];
    dataSource = new MatTableDataSource<VisoUser>([]);

    private _unsub = new Subject<void>();

    constructor(
        private _router: Router,
        private _actions$: Actions,
        private _store$: Store<AppState>,
        private _route: ActivatedRoute,
    ) {
        this.itemsPerPage = 20;
        this.page = 0;
    }

    ngOnInit() {
        this._actions$
            .pipe(
                ofType(
                    createContactRequestSuccess,
                    updateContactRequestSuccess,
                    deleteContactRequestSuccess,
                    reassignContactRelationshipsRequestSuccess,
                    updateManagedContactStatusRequestSuccess,
                ),
                tap(() => {
                    this.loadAll();
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(getContactsRequestSuccess, getContactsRequestFailed),
                tap(() => {
                    this.contactsLoading = false;
                }),
                takeUntil(this._unsub),
            )
            .subscribe();

        // Will run when paginated
        this._actions$
            .pipe(
                ofType(getContactsRequestSuccess),
                filter(({ headers }) => !!headers.get('x-total-count')),
                takeUntil(this._unsub),
            )
            .subscribe(({ contacts, headers }) => {
                const totalContactCount = +headers.get('x-total-count');
                this.resultsLength = totalContactCount;
                this.onSuccess(contacts, headers);
            });

        this.tableSort$
            .pipe(
                map((sortData) => sortData && sortData[0]),
                takeUntil(this._unsub),
            )
            .subscribe((sortItem) => {
                this.predicate = sortItem.prop;
                this.reverse = sortItem.dir !== 'desc';
            });

        this._route.queryParams.pipe(take(1)).subscribe(({ sort, page }) => {
            const sortPredicateFromUrl: string = !!sort ? (Array.isArray(sort) ? sort[0] : sort) : undefined;

            if (sortPredicateFromUrl) {
                const [prop, dir] = sortPredicateFromUrl.split(',');
                this.tableSort$.next([{ prop, dir }]);
            }

            this.page = +page || this.page;
        });

        this.loadAll();
    }

    loadAll() {
        this.contactsLoading = true;
        this._store$.dispatch(
            getContactsRequest({
                req: {
                    page: this.page,
                    size: this.itemsPerPage,
                    sort: this.sorter(),
                },
            }),
        );
    }

    setCurrentPage(pageEvent: PageEvent) {
        this.page = +pageEvent.pageIndex;
        this.itemsPerPage = pageEvent.pageSize;
        this.loadAll();
    }

    trackId(index: number, item: VisoUser) {
        return item.id;
    }

    deleteContact(contact: VisoUser) {
        setTimeout(() => this._store$.dispatch(openDeleteContactConfirmationModal({ contact })), 50);
    }

    updateManagedContactStatus(contact: VisoUser) {
        setTimeout(() => this._store$.dispatch(openUpdateContactStatusConfirmationModal({ contact })), 50);
    }

    formatUserRole(role: VisoUserRole): string {
        return role.replace('ROLE_', '').replace(/_/g, ' ');
    }

    onSortData(event: any) {
        this.predicate = event.active;
        this.sortDirection = event.direction;
        this.sorter();
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: { sort: `${this.predicate},${this.sortDirection}` },
            queryParamsHandling: 'merge',
        });
        this.loadAll();
    }

    private onSuccess(data: VisoUser[], headers: HttpHeaders): void {
        this.totalCurrentItems = +headers.get('x-total-count');
        this.dataSource.data = data;
    }

    private sorter() {
        if (this.predicate === 'name') {
            this.predicate = ['firstName', 'lastName'];
        } else if (this.predicate === 'subscriptions') {
            this.predicate = 'subscriptionCount';
        } else if (this.predicate === 'ownerships') {
            this.predicate = 'businessOwnerCount';
        } else if (this.predicate === 'businessUnit') {
            this.predicate = 'businessUnit.name';
        } else if (this.predicate === 'role') {
            this.predicate = 'authorities';
        }

        const result = [this.predicate + ',' + this.sortDirection];
        if (this.predicate !== 'id') {
            result.push('id');
        }
        return result;
    }

    ngOnDestroy(): void {
        this._unsub.next();
    }
}
