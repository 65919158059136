<h2 mat-dialog-title>{{ riskAdvisory.title }}</h2>
<mat-dialog-content>
    <div class="row risk-advisory-content">
        <div class="col-12 mb-2">
            <div class="d-flex">
                <app-chip type="basic" [condensed]="true">{{ RiskAdvisoryTypeLabels[riskAdvisory.type] }}</app-chip>
                <app-chip
                    *ngIf="riskAdvisory.isNthParty && !riskAdvisory.isVendor"
                    [type]="'info-outline'"
                    [condensed]="true"
                    class="ms-2"
                    >Nth party</app-chip
                >
                <app-chip *ngIf="riskAdvisory.isVendor" [type]="'info'" [condensed]="true" class="ms-2"
                    >Your vendor</app-chip
                >
            </div>
        </div>
        <div class="col-12 mb-3 mt-1">
            <h2 class="subtitle">
                {{ riskAdvisory.title }}
            </h2>
        </div>
        <div class="col-12 mb-1">
            <a class="mat-text-secondary" [externalLink]="riskAdvisory.url">
                <div class="d-flex">
                    <span class="base-line-height me-1">View source documentation </span>
                    <mat-icon inline="true" class="icon-small" fontSet="material-symbols-outlined">ios_share</mat-icon>
                </div>
            </a>
        </div>
        <div class="col-12">
            <span>
                {{ riskAdvisory.publishedDate | date: 'MMM d y' }}
            </span>
        </div>
        <div class="col-12 mt-3">
            <div
                class="risk-advisory-description"
                [innerHTML]="riskAdvisory | callback: getDescription | safeHtml"
            ></div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button color="warn" visoButton="text" type="button" mat-dialog-close>Close</button>
    <button
        type="button"
        mat-button
        visoButton="secondary"
        mat-dialog-close
        [routerLink]="viewNetworkGraphUrl.commands"
        [queryParams]="viewNetworkGraphUrl.extras?.queryParams"
        *ngIf="riskAdvisory.isVendor || riskAdvisory.isNthParty"
    >
        View in risk network
    </button>

    <button
        type="button"
        mat-button
        visoButton="primary"
        mat-dialog-close
        [routerLink]="viewRelationshipUrl.commands"
        [queryParams]="viewRelationshipUrl.extras?.queryParams"
        *ngIf="riskAdvisory.isVendor"
    >
        View relationship
    </button>
</mat-dialog-actions>
