import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ArtifactManagementService {
    private _artifactManagementResourceUrl = '/api/artifact-management';

    constructor(private _http: HttpClient) {}

    calculateFileHashForArtifactsMissingFileHash(): Observable<string> {
        return this._http.get(`${this._artifactManagementResourceUrl}/file-hash`, { responseType: 'text' });
    }

    refreshRiskNetworkData(): Observable<string> {
        return this._http.get(`${this._artifactManagementResourceUrl}/refresh`, { responseType: 'text' });
    }

    transferPublicArtifactsToTrustProfiles(): Observable<string> {
        return this._http.get(`${this._artifactManagementResourceUrl}/transfer-public-artifacts`, {
            responseType: 'text',
        });
    }

    rerunAntivirusScanForAllUnknownArtifacts(): Observable<string> {
        return this._http.get(`${this._artifactManagementResourceUrl}/rerun-unknown-artifacts`, {
            responseType: 'text',
        });
    }
}
