<div *dynamicContent="breadcrumbsContainerToken">
    <ol class="breadcrumb breadcrumb px-0 pb-0">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>
        <li class="breadcrumb-item active">Users</li>
    </ol>
</div>

<div class="content-heading">
    <div>Users ({{ resultsLength }})</div>
</div>
<div class="row">
    <div class="row">
        <div class="col">
            <button
                *jhiHasAnyAuthority="Roles.OrgAdmin"
                mat-button
                visoButton="primary"
                class="float-end"
                [routerLink]="['/', { outlets: { popup: ['user-new'] } }]"
            >
                Add User
            </button>
        </div>
    </div>
    <div class="row">
        <table
            mat-table
            [dataSource]="dataSource"
            class="viso-table"
            matSort
            [matSortActive]="sortByColumnName"
            [matSortDirection]="sortDirection"
            matSortDisableClear
            matSortStart="desc"
            [fixedLayout]="false"
            (matSortChange)="onSortData($event)"
            class="viso-table"
        >
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let row">
                    <span>{{ row.firstName + ' ' + row.lastName }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let row">
                    <span>{{ row.email }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="businessUnit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Business Unit</th>
                <td mat-cell *matCellDef="let row">
                    <span>{{ row.businessUnitName }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="defaultSubscriber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Default Subscriber</th>
                <td mat-cell *matCellDef="let row">
                    <mat-icon *ngIf="row.defaultSubscriber" fontSet="material-symbols-outlined">check_circle </mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="ownerships">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ownerships</th>
                <td mat-cell *matCellDef="let row">
                    <span>{{ row.businessOwnerCount }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="subscriptions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Subscriptions</th>
                <td mat-cell *matCellDef="let row">
                    <span>{{ row.subscriptionCount }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
                <td mat-cell *matCellDef="let row">
                    <span *ngIf="row.authorities && row.authorities.length">
                        <span class="badge badge-square bg-green" *ngFor="let role of row.authorities">
                            {{ role | callback: formatUserRole | titlecase }}
                        </span>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        *jhiHasNotAuthority="[Roles.OrgUser, Roles.ReadOnly]"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            mat-menu-item
                            class="button"
                            [routerLink]="['/', { outlets: { popup: 'user/' + row.id + '/edit' } }]"
                        >
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                        </button>
                        <button
                            [routerLink]="['/', { outlets: { popup: 'user/' + row.id + '/transfer' } }]"
                            [disabled]="row.subscriptionCount === 0 && row.businessOwnerCount === 0"
                            mat-menu-item
                        >
                            <mat-icon>swap_horiz</mat-icon>
                            <span>Manage Assignments</span>
                        </button>

                        <button *ngIf="!row.managedByIdp" mat-menu-item (click)="deleteContact(row)">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>

                        <button *ngIf="row.managedByIdp" mat-menu-item (click)="updateManagedContactStatus(row)">
                            <mat-icon>{{ row.status === UserStatus.ACTIVE ? 'toggle_on' : 'toggle_off' }}</mat-icon>
                            <span>{{ row.status === UserStatus.ACTIVE ? 'Deactivate' : 'Activate' }}</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [ngClass]="{ inactive: row.status === UserStatus.INACTIVE }"
            ></tr>
        </table>
        <mat-paginator
            [pageSizeOptions]="[10, 20, 30]"
            [showFirstLastButtons]="true"
            [length]="resultsLength"
            [pageSize]="itemsPerPage"
            [pageIndex]="page"
            (page)="setCurrentPage($event)"
            aria-label="Select page of assessments"
        >
        </mat-paginator>
    </div>
</div>
