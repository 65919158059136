import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { merge, Observable, Subject } from 'rxjs';
import { filter, map, startWith, take, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { FileDropDirective, FileItem, FileUploader } from '@shared/file-upload';
import { LicenseType, UserStatus, VisoUser, VisoUserRole } from '@entities/viso-user';
import { ArtifactOwnershipLevel, UploadFileArtifactRequest } from '@entities/artifact';
import { FormUtilsService } from '@shared/utils/form-utils.service';
import { urlValidator } from '@shared/validators/url-validator';
import { PrimaryVendorContact } from '../../../primary-vendor-contact';
import { getUserAccount, getUserAuthority } from '../../../session/redux/session.selectors';
import { StartAssessmentPayload } from '../../models/start-assessment-payload';
import {
    AssessmentActions,
    getOrgUsersRequest,
    getOrgUsersRequestSuccess,
    startAssessmentRequest,
    startAssessmentRequestFailed,
    startAssessmentRequestSuccess,
} from '../../redux/actions/assessments.actions';
import { SuggestedContact, TrustCenter } from '@entities/relationship';
import { getRelationshipSuggestedContacts } from '../../redux/request.selectors';
import { RefSelectOption } from '@shared/model/select-options';
import { MatDialogRef } from '@angular/material/dialog';
import { ControlsOf } from '@shared/model/controls-of';
import { FollowupType, VendorCancelReason } from '@entities/assessment';
import { FeatureFlags } from '@shared/enums/feature-flags';
import { FeatureFlagService } from '@shared/services/featureflag.service';
import { Risk } from '@entities/risk-assessment';

enum ThirdPartyOptions {
    VendorDirectoryContact,
    PrimaryVendorContact,
    OtherRecipient,
}

type SuggestedContactOption = {
    id: SuggestedContact;
    text: string;
};

interface ThirdPartyRecipient {
    firstName: string;
    lastName: string;
    email: string;
}

enum AssessmentInfoTypeValue {
    THIRD_PARTY_INFO = 'THIRD_PARTY_INFO',
    UPLOAD_ARTIFACTS = 'UPLOAD_ARTIFACTS',
    PUBLIC_URLS = 'PUBLIC_URLS',
    CONTINUE_WITH_PUBLIC_INFO = 'CONTINUE_WITH_PUBLIC_INFO',
}

interface StartAssessmentFormGroup {
    useThirdPartyOrOtherRecipient: FormControl<ThirdPartyOptions | null>;
    suggestedContact?: FormControl<SuggestedContact>;
    otherRecipient?: FormGroup<ControlsOf<ThirdPartyRecipient>>;
    optionalMessage: FormControl<string>;
    publicDocUrl?: FormControl<string>;
    publicDocsUrls?: FormArray<FormControl<string>>;
    assessmentCreator?: FormControl<VisoUser>;
    assessmentInfoTypeSelection: FormControl<AssessmentInfoTypeValue>;
    followupType: FormControl<FollowupType>;
    followupRiskThreshold: FormControl<Risk>;
}

@Component({
    selector: 'app-start-assessment',
    templateUrl: './start-assessment.component.html',
    styleUrls: ['./start-assessment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartAssessmentComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    requestId: number;

    @Input()
    clientId: number;

    @Input()
    primaryVendorContact: PrimaryVendorContact;

    @Input()
    recertification: boolean;

    @Input({ required: true })
    thirdPartyOrgName: string;

    @Input()
    trustCenter: TrustCenter;

    uploader: FileUploader;

    startAssessmentFormGroup: FormGroup<StartAssessmentFormGroup>;
    publicDocsUrlsValues$: Observable<string[]>;
    doesCurrentUserHaveTrialLicense$: Observable<boolean>;
    currentUserAvailableAssessments$: Observable<number>;
    conciergeAssessmentsEnabled$: Observable<boolean>;
    currentUserRemainingConciergeAssessments$: Observable<number>;
    uploadingFiles$: Observable<boolean>;
    onDrag$: Observable<boolean>;
    suggestedContacts: SuggestedContactOption[];
    potentialAssessmentCreators$: Observable<RefSelectOption<VisoUser>[]>;

    AssessmentCancelledReason = VendorCancelReason;
    ThirdPartyOptions = ThirdPartyOptions;
    AssessmentActions = AssessmentActions;
    VisoUserRole = VisoUserRole;
    AssessmentInfoTypeValue = AssessmentInfoTypeValue;

    readonly MAX_TRUST_CENTER_LENGTH = 50;

    @ViewChild(FileDropDirective)
    private _fileDropZone: FileDropDirective;

    private _unsub$ = new Subject<void>();
    private _fileQueueChange = new Subject<void>();

    constructor(
        private _fb: FormBuilder,
        private _store$: Store,
        private _actions$: Actions,
        private _formUtils: FormUtilsService,
        public _dialogRef: MatDialogRef<StartAssessmentComponent>,
        private _featureFlagService: FeatureFlagService,
    ) {}

    get assessmentInfoTypeValue(): AssessmentInfoTypeValue {
        return this.startAssessmentFormGroup.controls.assessmentInfoTypeSelection.getRawValue();
    }

    get hasPrimaryVendorContact(): boolean {
        return !!this.primaryVendorContact;
    }

    get useThirdPartyOrOtherRecipientFormControl() {
        return this.startAssessmentFormGroup.controls.useThirdPartyOrOtherRecipient;
    }

    get recipientFormGroup() {
        return this.startAssessmentFormGroup.controls.otherRecipient;
    }

    get suggestedContactFormControl() {
        return this.startAssessmentFormGroup.controls.suggestedContact;
    }

    get assessmentCreatorFormControl() {
        return this.startAssessmentFormGroup.controls.assessmentCreator;
    }

    get primaryContactName(): string {
        return `${this.primaryVendorContact?.firstName} ${this.primaryVendorContact?.lastName}`;
    }

    get primaryContactEmail(): string {
        return this.primaryVendorContact?.email;
    }

    get optionalMessageFormControl() {
        return this.startAssessmentFormGroup.controls.optionalMessage;
    }

    get followupTypeFormControl() {
        return this.startAssessmentFormGroup.controls.followupType;
    }

    get followupRiskThresholdControl() {
        return this.startAssessmentFormGroup.controls.followupRiskThreshold;
    }

    get publicDocUrlFormControl(): FormControl<string> {
        return this.startAssessmentFormGroup.controls.publicDocUrl;
    }

    get publicDocsUrlsFormArray(): FormArray<FormControl<string>> {
        return this.startAssessmentFormGroup.controls.publicDocsUrls;
    }

    get showPublicDocUrlError(): boolean {
        return this.publicDocUrlFormControl.errors?.pattern && this.isPublicDocUrlTouchedOrDirty;
    }

    get showRecipientFirstNameRequiredError(): boolean {
        const firstNameFormControl = this.recipientFormGroup.controls.firstName;
        return firstNameFormControl.errors?.required && this.isRecipientFirstNameTouchedOrDirty;
    }

    get showRecipientFirstNameMinLengthError(): boolean {
        const firstNameFormControl = this.recipientFormGroup.controls.firstName;
        return firstNameFormControl.errors?.minlength && this.isRecipientFirstNameTouchedOrDirty;
    }

    get showRecipientLastNameRequiredError(): boolean {
        const lastNameFormControl = this.recipientFormGroup.controls.lastName;
        return lastNameFormControl.errors?.required && this.isRecipientLastNameTouchedOrDirty;
    }

    get showRecipientLastNameMinLengthError(): boolean {
        const lastNameFormControl = this.recipientFormGroup.controls.lastName;
        return lastNameFormControl.errors?.minlength && this.isRecipientLastNameTouchedOrDirty;
    }

    get showRecipientEmailRequiredError(): boolean {
        const emailFormControl = this.recipientFormGroup.controls.email;
        return emailFormControl.errors?.required && this.isRecipientEmailTouchedOrDirty;
    }

    get showRecipientEmailFormatError(): boolean {
        const emailFormControl = this.recipientFormGroup.controls.email;
        return emailFormControl.errors?.email && this.isRecipientEmailTouchedOrDirty;
    }

    get showSelectAtLeastOneMethodError(): boolean {
        return this.startAssessmentFormGroup.errors?.allNoAnswers;
    }

    get titleLabel(): string {
        return this.recertification ? 'Start recertification' : 'Start an assessment';
    }

    get submitButtonLabel(): string {
        return this.recertification ? 'Start recertification' : 'Start assessment';
    }

    get addUrlButtonDisabled(): boolean {
        const control = this.publicDocUrlFormControl;
        return !control.value || control.invalid;
    }

    get trustCenterIsFromSafeBase(): boolean {
        return !!this.trustCenter ? this.trustCenter.trustCenterType == 'SAFEBASE' : false;
    }

    get trustCenterLinkTooltip(): string | null {
        return this.trustCenter?.url.length > this.MAX_TRUST_CENTER_LENGTH ? this.trustCenter.url : null;
    }

    private get isPublicDocUrlTouchedOrDirty(): boolean {
        return this.publicDocUrlFormControl.dirty || this.publicDocUrlFormControl.touched;
    }

    private get isRecipientFirstNameTouchedOrDirty(): boolean {
        const firstNameFormControl = this.recipientFormGroup.controls.firstName;
        return firstNameFormControl.dirty || firstNameFormControl.touched;
    }

    private get isRecipientLastNameTouchedOrDirty(): boolean {
        const lastNameFormControl = this.recipientFormGroup.controls.lastName;
        return lastNameFormControl.dirty || lastNameFormControl.touched;
    }

    private get isRecipientEmailTouchedOrDirty(): boolean {
        const emailFormControl = this.recipientFormGroup.controls.email;
        return emailFormControl.dirty || emailFormControl.touched;
    }

    ngOnInit(): void {
        this.loadFeatureFlags();

        this._store$
            .pipe(select(getRelationshipSuggestedContacts), takeUntil(this._unsub$))
            .subscribe((suggestedContacts) => {
                this.suggestedContacts = suggestedContacts.map((contact) => ({
                    id: contact,
                    text: `${contact.firstName} ${contact.lastName} • ${contact.email}`,
                }));
            });

        this.potentialAssessmentCreators$ = this._actions$.pipe(
            ofType(getOrgUsersRequestSuccess),
            map(({ users }) =>
                users
                    .filter((user) => user.status === UserStatus.ACTIVE)
                    .map<RefSelectOption<VisoUser>>((user) => ({
                        ref: user,
                        name: `${user.firstName} ${user.lastName} • ${user.email}`,
                    })),
            ),
        );

        this.uploader = new FileUploader({
            url: '',
            maxFileSize: 100000000,
        });

        this.uploader.onAfterAddingFile = () => {
            this._fileQueueChange.next();
        };

        this.startAssessmentFormGroup = this._fb.group<StartAssessmentFormGroup>({
            useThirdPartyOrOtherRecipient: this._fb.control(null),
            suggestedContact: this._fb.control(null),
            otherRecipient: this._fb.group({
                firstName: this._fb.control(null, {
                    validators: Validators.required,
                    nonNullable: true,
                }),
                lastName: this._fb.control(null, {
                    validators: Validators.required,
                    nonNullable: true,
                }),
                email: this._fb.control(null, {
                    validators: [Validators.required, Validators.email],
                    nonNullable: true,
                }),
            }),
            optionalMessage: this._fb.control(''),
            publicDocUrl: this._fb.control('', urlValidator),
            publicDocsUrls: this._fb.array<FormControl<string>>([]),
            assessmentCreator: this._fb.control({ value: null, disabled: true }, Validators.required),
            assessmentInfoTypeSelection: this._fb.control(null, Validators.required),
            followupType: this._fb.control(FollowupType.MANUAL, Validators.required),
            followupRiskThreshold: this._fb.control(Risk.LOW, Validators.required),
        });

        this._store$
            .select(getUserAuthority(VisoUserRole.Auditor))
            .pipe(
                filter((isAuditor) => isAuditor),
                take(1),
            )
            .subscribe(() => {
                this._store$.dispatch(getOrgUsersRequest({ orgId: this.clientId }));
                this.startAssessmentFormGroup.controls.assessmentCreator.enable();
            });

        const controls = this.startAssessmentFormGroup.controls;
        controls.otherRecipient.disable();
        controls.suggestedContact.disable();
        controls.publicDocsUrls.disable();

        controls.assessmentInfoTypeSelection.valueChanges.pipe(takeUntil(this._unsub$)).subscribe((value) => {
            if (value === AssessmentInfoTypeValue.THIRD_PARTY_INFO) {
                if (this.hasPrimaryVendorContact) {
                    controls.useThirdPartyOrOtherRecipient.setValue(ThirdPartyOptions.PrimaryVendorContact);
                } else {
                    controls.useThirdPartyOrOtherRecipient.setValue(ThirdPartyOptions.OtherRecipient);
                }
            } else {
                controls.useThirdPartyOrOtherRecipient.setValue(null);
                controls.optionalMessage.setValue(null);
            }

            controls.publicDocUrl.reset();
            controls.publicDocsUrls.clear();
            if (value === AssessmentInfoTypeValue.PUBLIC_URLS) {
                controls.publicDocsUrls.enable();
                controls.publicDocsUrls.addValidators(Validators.required);
            } else {
                controls.publicDocsUrls.disable();
                controls.publicDocsUrls.removeValidators(Validators.required);
            }
            controls.publicDocsUrls.updateValueAndValidity();
        });

        controls.useThirdPartyOrOtherRecipient.valueChanges
            .pipe(
                tap((value: ThirdPartyOptions) => {
                    controls.otherRecipient.reset();
                    if (value === ThirdPartyOptions.PrimaryVendorContact || value === null) {
                        controls.otherRecipient.disable();
                        controls.suggestedContact.disable();
                    } else if (value === ThirdPartyOptions.VendorDirectoryContact) {
                        controls.otherRecipient.disable();
                        controls.suggestedContact.enable();
                    } else if (value === ThirdPartyOptions.OtherRecipient) {
                        controls.suggestedContact.disable();
                        controls.otherRecipient.enable();
                    }
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._fileQueueChange
            .pipe(
                tap(() => this.startAssessmentFormGroup.updateValueAndValidity()),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.publicDocsUrlsValues$ = controls.publicDocsUrls.valueChanges;

        const currentUser$ = this._store$.pipe(select(getUserAccount));

        this.doesCurrentUserHaveTrialLicense$ = currentUser$.pipe(
            map((currentUser) => currentUser.clientLicense?.licenseType),
            map((licenseType) => [LicenseType.TRIAL, LicenseType.PROD_TRIAL].includes(licenseType)),
        );

        this.currentUserAvailableAssessments$ = currentUser$.pipe(
            map(
                (currentUser) =>
                    currentUser.clientLicense?.maxRelationshipsAssessed -
                    currentUser.clientLicense?.relationshipsAssessedCount,
            ),
        );

        this.currentUserRemainingConciergeAssessments$ = currentUser$.pipe(
            filter(Boolean),
            map((currentUser) =>
                currentUser.clientLicense?.maxConciergeAssessments == -1
                    ? -1
                    : currentUser.clientLicense?.maxConciergeAssessments -
                      currentUser.clientLicense?.conciergeAssessmentCount,
            ),
        );

        this.uploadingFiles$ = merge(
            this._actions$.pipe(
                ofType(startAssessmentRequest),
                map(() => true),
            ),
            this._actions$.pipe(
                ofType(startAssessmentRequestSuccess, startAssessmentRequestFailed),
                map(() => false),
            ),
        ).pipe(startWith(false));

        this._actions$
            .pipe(ofType(startAssessmentRequestSuccess), takeUntil(this._unsub$))
            .subscribe(() => this.close());

        this.setupFormFileUploadValidation();
    }

    private loadFeatureFlags(): void {
        this.conciergeAssessmentsEnabled$ = this._featureFlagService.flagsLoaded.pipe(
            map((flags) => flags[FeatureFlags.CONCIERGE_ASSESSMENTS] ?? false),
        );
    }

    ngAfterViewInit(): void {
        this.onDrag$ = this._fileDropZone.fileOver.pipe(
            throttleTime(500, undefined, { leading: true, trailing: true }),
        );

        this._fileDropZone.onFileDrop
            .pipe(
                tap(() =>
                    this.startAssessmentFormGroup.controls.assessmentInfoTypeSelection.setValue(
                        AssessmentInfoTypeValue.UPLOAD_ARTIFACTS,
                    ),
                ),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    submit(): void {
        const payload = this.getPayload();
        this._store$.dispatch(startAssessmentRequest({ payload }));
    }

    setArtifactPassword(password: string, item: FileItem): void {
        item.formData = { password };
    }

    removeItem(fileItem: FileItem): void {
        fileItem.remove();
        this._fileQueueChange.next();
    }

    addUrl(): void {
        this.publicDocsUrlsFormArray.push(this._fb.control(this.publicDocUrlFormControl.value));
        this.publicDocUrlFormControl.reset();
    }

    removeUrl(index: number): void {
        this.publicDocsUrlsFormArray.removeAt(index);
    }

    close(): void {
        this.uploader.clearQueue();
        this._dialogRef.close();
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }

    private getPayload(): StartAssessmentPayload {
        const {
            useThirdPartyOrOtherRecipient,
            suggestedContact,
            otherRecipient,
            optionalMessage,
            publicDocsUrls,
            assessmentCreator,
            assessmentInfoTypeSelection,
            followupType,
            followupRiskThreshold,
        } = this._formUtils.getCleanTypedFormGroupValue(this.startAssessmentFormGroup);
        let recipient: ThirdPartyRecipient;
        const documentsOnly = assessmentInfoTypeSelection !== AssessmentInfoTypeValue.THIRD_PARTY_INFO;
        if (!documentsOnly) {
            let selectedRecipient: ThirdPartyRecipient | PrimaryVendorContact;
            switch (useThirdPartyOrOtherRecipient) {
                case ThirdPartyOptions.PrimaryVendorContact:
                    selectedRecipient = this.primaryVendorContact;
                    break;
                case ThirdPartyOptions.VendorDirectoryContact:
                    selectedRecipient = suggestedContact;
                    break;
                case ThirdPartyOptions.OtherRecipient:
                    selectedRecipient = otherRecipient;
                    break;
            }
            const { firstName, lastName, email } = selectedRecipient;
            recipient = {
                firstName,
                lastName,
                email,
            };
        }

        return {
            relationshipId: this.requestId,
            documentsOnly,
            files:
                assessmentInfoTypeSelection === AssessmentInfoTypeValue.UPLOAD_ARTIFACTS
                    ? this.uploader.queue.map<UploadFileArtifactRequest>((file) => ({
                          file: file._file,
                          fileArtifactMetaData: {
                              artifactPassword: file.formData?.password,
                              artifactOwnershipType: ArtifactOwnershipLevel.PRIVATE,
                          },
                      }))
                    : null,
            recipient,
            optionalMessage,
            publicDocumentsInfo:
                assessmentInfoTypeSelection === AssessmentInfoTypeValue.PUBLIC_URLS ? publicDocsUrls.join(',') : null,
            assessmentCreatorId: assessmentCreator?.id,
            continueWithPublicInformation:
                assessmentInfoTypeSelection === AssessmentInfoTypeValue.CONTINUE_WITH_PUBLIC_INFO,
            followupType: documentsOnly ? FollowupType.MANUAL : followupType,
            followupRiskThreshold: followupType === FollowupType.AUTOMATIC ? followupRiskThreshold : null,
        };
    }

    private setupFormFileUploadValidation(): void {
        // Need to manually validate the form since there is no way for reactive forms to track files being uploaded.

        // Validate on upload/removal of files.
        this._fileQueueChange.pipe(takeUntil(this._unsub$)).subscribe(() => this.invalidateFormForMissingFiles());

        // Validate when changing the info type selection.
        this.startAssessmentFormGroup.controls.assessmentInfoTypeSelection.valueChanges
            .pipe(takeUntil(this._unsub$))
            .subscribe(() => this.invalidateFormForMissingFiles());
    }

    private invalidateFormForMissingFiles(): void {
        const errorKey = 'Missing file(s)';
        const formControl = this.startAssessmentFormGroup.controls.assessmentInfoTypeSelection;
        const missingInfoTypeSelection = formControl.value;
        const missingRequiredFile =
            missingInfoTypeSelection === AssessmentInfoTypeValue.UPLOAD_ARTIFACTS && !this.uploader.queue.length;
        if (missingRequiredFile) {
            formControl.setErrors({
                [errorKey]: true,
            });
        } else if (formControl.hasError(errorKey)) {
            delete this.startAssessmentFormGroup.controls.assessmentInfoTypeSelection.errors['Missing file(s)'];
            this.startAssessmentFormGroup.controls.assessmentInfoTypeSelection.updateValueAndValidity();
        }
    }
}
