<div class="row mb-2">
    <div class="col">
        <h3 class="lead">Business owner</h3>
    </div>
    <div class="col-auto">
        <a
            *jhiHasNotAuthority="[Roles.OrgUser, Roles.ReadOnly]"
            exportRemovableAction
            class="text-primary"
            [routerLink]="editLink"
            [replaceUrl]="true"
        >
            Edit
        </a>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="d-flex flex-column">
            <p class="mb-0" data-pw="rdpBusinessOwnerFullName">{{ businessOwnerName }}</p>
            <small class="mb-0" data-pw="rdpBusinessOwnerEmail">{{ businessOwnerEmail }}</small>
            <small class="text-muted mb-0">{{ businessOwnerBusinessUnitName }}</small>
        </div>
    </div>
</div>
