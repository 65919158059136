import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, merge, of, Subject } from 'rxjs';
import { hasDomainValidator } from '../../../shared/validators/domain-validator';
import { noWhitespaceValidator } from '../../../shared/validators/whitespace-validator';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { VisoUser } from '../../../entities/viso-user';
import { getUserAccount } from '../../session/redux/session.selectors';
import { BusinessUnit } from '../../../entities/business-unit';
import { RefSelectOption } from '../../../shared/model/select-options';
import {
    getBusinessUnitsRequest,
    getBusinessUnitsRequestSuccess,
} from '../../business-unit-management/redux/business-unit-management.actions';
import {
    getContactsRequest,
    getContactsRequestSuccess,
} from '../../contact-management/redux/contact-management.actions';
import { getRelationshipSubscribersRequest, getRelationshipSubscribersRequestSuccess } from '../redux/request.actions';
import { FormUtilsService } from '../../../shared/utils/form-utils.service';
import { SubscriberFormGroup } from './add-subscriber-modal.model';
import { isUniqueContactValidator } from '../../../shared/validators/unique-contact-validator';
import { RequestActions, subscribeContactRequest, subscribeContactRequestSuccess } from '../redux/request.actions';
import { createAndSubscribeContactRequest, SubscriberActions } from '../redux/actions/subscriber.actions';

@Component({
    selector: 'add-subscriber-modal',
    templateUrl: './add-subscriber-modal.component.html',
    styleUrls: ['./add-subscriber-modal.component.scss'],
})
export class AddSubscriberModalComponent implements OnInit, OnDestroy {
    @Input()
    requestId: number;

    subscriberFormGroup: UntypedFormGroup;

    businessUnits$ = new BehaviorSubject<RefSelectOption<BusinessUnit>[]>([]);
    contacts$ = new BehaviorSubject<RefSelectOption<VisoUser>[]>([]);
    currentUser: VisoUser;
    orgDomains: string[] = [];

    selectContactFormControl = new UntypedFormControl(true);
    selectBusinessUnitFormControl = new UntypedFormControl(true);

    RequestActions = RequestActions;
    SubscriberActions = SubscriberActions;

    private _unsub$ = new Subject<void>();

    constructor(
        private _fb: UntypedFormBuilder,
        private _store$: Store,
        private _actions$: Actions,
        private _activeModal: NgbActiveModal,
        private _formUtils: FormUtilsService,
    ) {}

    get showContactList(): boolean {
        return this.selectContactFormControl.value;
    }

    get showBusinessUnitList(): boolean {
        return this.selectBusinessUnitFormControl.value;
    }

    get showContactAlreadyExistsError(): boolean {
        const emailFormControl = this.subscriberFormGroup.controls.email;
        return emailFormControl.hasError('emailAlreadyExists') && this.isEmailDirtyOrTouched;
    }

    get showInvalidEmailDomainError(): boolean {
        const emailFormControl = this.subscriberFormGroup.controls.email;
        return emailFormControl.hasError('invalidEmailDomain') && this.isEmailDirtyOrTouched;
    }

    get showInvalidEmailSyntaxError(): boolean {
        const emailFormControl = this.subscriberFormGroup.controls.email;
        return emailFormControl.hasError('email') && this.isEmailDirtyOrTouched;
    }

    private get isEmailDirtyOrTouched(): boolean {
        const emailFormControl = this.subscriberFormGroup.controls.email;
        return emailFormControl.dirty || emailFormControl.touched;
    }

    get showFirstNameLengthError(): boolean {
        return !!this.subscriberFormGroup.controls.firstName.errors?.maxlength;
    }

    get showLastNameLengthError(): boolean {
        return !!this.subscriberFormGroup.controls.lastName.errors?.maxlength;
    }

    get showEmailLengthError(): boolean {
        const emailFormControl = this.subscriberFormGroup.controls.email;
        return emailFormControl.hasError('maxlength');
    }

    ngOnInit(): void {
        this.subscriberFormGroup = this._fb.group({
            orgId: [null],
            contact: [null],
            firstName: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            lastName: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
            email: [null],
            businessUnit: [null, Validators.required],
            businessUnitName: [null, [Validators.required, noWhitespaceValidator]],
            managedByIdp: [false],
        });

        this._store$.pipe(select(getUserAccount), takeUntil(this._unsub$)).subscribe((account) => {
            this.currentUser = account;
            this.orgDomains = account.orgDomains;
        });

        this._store$.dispatch(getBusinessUnitsRequest(null));
        this._store$.dispatch(getContactsRequest(null));
        this._store$.dispatch(getRelationshipSubscribersRequest({ requestId: this.requestId }));

        const controls = this.subscriberFormGroup.controls;

        this.selectContactFormControl.valueChanges
            .pipe(
                tap((selectContact: boolean) => {
                    if (selectContact) {
                        controls.firstName.disable();
                        controls.lastName.disable();
                        controls.email.disable();
                        controls.contact.enable();
                    } else {
                        controls.firstName.enable();
                        controls.lastName.enable();
                        controls.email.enable();
                        controls.contact.disable();
                    }
                    controls.firstName.setValue('');
                    controls.lastName.setValue('');
                    controls.email.setValue('');
                    controls.contact.setValue(null);
                    controls.firstName.updateValueAndValidity({ emitEvent: false });
                    controls.lastName.updateValueAndValidity({ emitEvent: false });
                    controls.email.updateValueAndValidity({ emitEvent: false });

                    this.subscriberFormGroup.controls.email.setValidators(
                        Validators.compose([
                            Validators.required,
                            Validators.maxLength(100),
                            Validators.email,
                            hasDomainValidator(this.orgDomains),
                            isUniqueContactValidator(this.contacts$, selectContact, null),
                        ]),
                    );
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        combineLatest([
            this.businessUnits$.pipe(filter((bu) => !!bu.length)),
            merge(of(controls.contact.value), controls.contact.valueChanges),
        ])
            .pipe(
                map(([, contact]) => contact),
                tap((contact: VisoUser) => {
                    const { firstName, lastName, email, businessUnitId, orgId, managedByIdp } = contact || {};
                    controls.firstName.setValue(firstName);
                    controls.lastName.setValue(lastName);
                    controls.email.setValue(email);
                    const businessUnit = this.businessUnits$.value.find((bu) => bu.ref.id === businessUnitId)?.ref;
                    controls.businessUnit.setValue(businessUnit);
                    controls.businessUnitName.setValue(businessUnit?.name);
                    controls.orgId.setValue(orgId);
                    controls.managedByIdp.setValue(managedByIdp);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        controls.businessUnit.valueChanges
            .pipe(
                tap((bu: BusinessUnit) => {
                    controls.businessUnitName.setValue(bu?.name);
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this.selectBusinessUnitFormControl.valueChanges
            .pipe(
                tap((selectBusinessUnit: boolean) => {
                    if (selectBusinessUnit) {
                        controls.businessUnit.setValidators(Validators.required);
                        controls.businessUnitName.clearValidators();
                    } else {
                        controls.businessUnit.clearValidators();
                        controls.businessUnitName.setValidators(
                            Validators.compose([Validators.required, noWhitespaceValidator]),
                        );
                    }
                    controls.businessUnit.setValue('');
                    controls.businessUnitName.setValue('');
                    controls.businessUnit.updateValueAndValidity({ emitEvent: false });
                    controls.businessUnitName.updateValueAndValidity({ emitEvent: false });
                }),
                takeUntil(this._unsub$),
            )
            .subscribe();

        this._actions$
            .pipe(
                ofType(getBusinessUnitsRequestSuccess),
                map(({ businessUnits }) =>
                    businessUnits.map<RefSelectOption<BusinessUnit>>((businessUnit) => ({
                        name: businessUnit.name,
                        ref: businessUnit,
                    })),
                ),
                takeUntil(this._unsub$),
            )
            .subscribe((businessUnitsOptions) => this.businessUnits$.next(businessUnitsOptions));

        combineLatest([
            this._actions$.pipe(ofType(getRelationshipSubscribersRequestSuccess)),
            this._actions$.pipe(ofType(getContactsRequestSuccess)),
            this._store$.select(getUserAccount),
        ])
            .pipe(
                map(([{ subscribers }, { contacts }, currentAccount]) => {
                    var selectOptions = contacts.map<RefSelectOption<VisoUser>>((contact) => ({
                        ref: contact,
                        name: `${contact.firstName} ${contact.lastName}  •  ${contact.email}  •  ${contact.businessUnitName}`,
                    }));

                    const subscribedIds = new Set(subscribers.map((subscriber) => subscriber.id));

                    let contactList = selectOptions.filter((sub) => !subscribedIds.has(sub.ref.id));

                    let contactMatchingCurrentUser = contactList.find(
                        (sub) => sub.ref.email.toLowerCase() === currentAccount.email.toLowerCase(),
                    )?.ref;
                    if (contactMatchingCurrentUser) {
                        contactList = contactList.filter(
                            (sub) => sub.ref.email.toLowerCase() !== currentAccount.email.toLowerCase(),
                        );
                    }

                    contactList = contactList.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

                    if (contactMatchingCurrentUser && !subscribedIds.has(contactMatchingCurrentUser.id)) {
                        contactList.unshift({
                            ref: contactMatchingCurrentUser,
                            name: `${currentAccount.firstName} ${currentAccount.lastName}  •  ${currentAccount.email}  •  ${contactMatchingCurrentUser.businessUnitName}`,
                        });
                    }
                    return contactList;
                }),
                takeUntil(this._unsub$),
            )
            .subscribe((businessOwnerContactOptions) => this.contacts$.next(businessOwnerContactOptions));

        this._actions$
            .pipe(
                ofType(subscribeContactRequestSuccess),
                tap(() => this.close()),
                takeUntil(this._unsub$),
            )
            .subscribe();
    }

    private getSubscriberPayload(): VisoUser {
        const { contact, firstName, lastName, email, businessUnit, businessUnitName } =
            this._formUtils.getCleanFormGroupValue<SubscriberFormGroup>(this.subscriberFormGroup, true);
        return {
            ...contact,
            orgId: contact?.orgId || this.currentUser?.orgId,
            firstName,
            lastName,
            email,
            businessUnitId: businessUnit?.id,
            businessUnitName: businessUnitName || businessUnit?.name,
            managedByIdp: contact?.managedByIdp || false,
            defaultSubscriber: contact?.defaultSubscriber || false,
        };
    }

    save() {
        const requestId = this.requestId;
        const subscriber = this.getSubscriberPayload();

        if (this.showContactList) {
            this._store$.dispatch(subscribeContactRequest({ requestId, contactId: subscriber?.id }));
        } else {
            this._store$.dispatch(createAndSubscribeContactRequest({ requestId, contact: subscriber }));
        }
    }

    close() {
        this._activeModal.close();
    }

    ngOnDestroy(): void {
        this._unsub$.next();
    }
}
