import { BaseEntity } from '../../shared';
import { VisoUser } from '../viso-user';
import { Artifact } from '../artifact';

export enum OrgType {
    Client = 'CLIENT',
    Managed = 'MANAGED',
}

export enum OrgStatus {
    ONBOARDED = 'ONBOARDED',
    OFFBOARDING = 'OFFBOARDING',
    NOT_ONBOARDED = 'NOT_ONBOARDED',
}

export enum OrgSize {
    NONE = 'NONE',
    SMALLEST = 'SMALLEST',
    SMALL = 'SMALL',
    MEDIUM_SMALL = 'MEDIUM_SMALL',
    MEDIUM_LARGE = 'MEDIUM_LARGE',
    LARGE = 'LARGE',
    XLARGE = 'XLARGE',
    ENTERPRISE = 'ENTERPRISE',
    HUGE = 'HUGE',
}

export enum OrgSizeLabels {
    NONE = 'Not Specified',
    SMALLEST = '1-10',
    SMALL = '11-50',
    MEDIUM_SMALL = '51-200',
    MEDIUM_LARGE = '201-500',
    LARGE = '501-1K',
    XLARGE = '1K-5K',
    ENTERPRISE = '5K-10K',
    HUGE = '10K+',
}

export class Org implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public legalName?: string,
        public domains?: string[],
        public homepage?: string,
        public description?: string,
        public industry?: string,
        public createdDate?: any,
        public updatedDate?: any,
        public logoUrl?: string,
        public faviconUrl?: string,
        public orgType?: OrgType,
        public subscriptionStartDate?: any,
        public subscriptionEndDate?: any,
        public adminId?: number,
        public artifacts?: Artifact[],
        public orgControls?: BaseEntity[],
        public orgSize?: OrgSize,
        public users?: VisoUser[],
        public city?: string,
        public region?: string,
        public stateCode?: string,
        public countryCode?: string,
        public emailAddress?: string,
        public status?: OrgStatus,
    ) {}
}

export interface VendorFromExistingRelationshipRequest {
    id: number;
    name: string;
}

export interface OrgView {
    id: number;
    name: string;
    status: OrgStatus;
    domains: string[];
}

export interface OrgDetailsResponse {
    id: number;
    address: string;
    brandingColor: string;
    city: string;
    countryCode: string;
    description: string;
    domains: string[];
    faviconUrl: string;
    homepage: string;
    industry: string;
    legalName: string;
    logoUrl: string;
    managedById: number;
    name: string;
    orgSize: OrgSize;
    orgType: OrgType;
    postalCode: string;
    region: string;
    stateCode: string;
    emailAddress: string;
    urls: Record<OrgUrlType, string>;

    privacyPolicyUrl: string; // FE only - due to use of ngModel in YourOrganizationComponent
}

export enum OrgUrlType {
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    INCIDENT_RESPONSE = 'INCIDENT_RESPONSE',
    STATUS_PAGE = 'STATUS_PAGE',
    CUSTOMER_LIST = 'CUSTOMER_LIST',
    REGULATORY_FILINGS = 'REGULATORY_FILINGS',
    COMPLIANCE_STANDARDS_1 = 'COMPLIANCE_STANDARDS_1',
    COMPLIANCE_STANDARDS_2 = 'COMPLIANCE_STANDARDS_2',
}

export interface SupplementalQuestionnaireConfig {
    id: string;
    questionnaireName: string;
    questions: SupplementalQuestionnaireQuestionView[];
    enabled: boolean;
    activeStatus: ActiveStatus;
}

export interface SupplementalQuestionnaireQuestionView {
    id: number;
    question: string;
}

export enum ActiveStatus {
    ACTIVE = 'ACTIVE',
    DELETED = 'DELETED',
}
