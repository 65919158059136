import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RelationshipService } from '@entities/relationship';
import { AssessmentService } from '@entities/assessment';
import { SnackbarService } from '@shared/components/snackbar/snackbar.service';
import { getUserProfileSelector } from '../../../session/redux/session.selectors';
import { StartAssessmentComponent } from '../../assessments/start-assessment/start-assessment.component';
import {
    cancelRequestLatestAssessment,
    cancelRequestLatestAssessmentRequest,
    cancelRequestLatestAssessmentRequestFailed,
    cancelRequestLatestAssessmentRequestSuccess,
    confirmProceedWithAvailableData,
    getOrgUsersRequest,
    getOrgUsersRequestFailed,
    getOrgUsersRequestSuccess,
    getRequestAssessmentEmailsRequest,
    getRequestAssessmentEmailsRequestFailed,
    getRequestAssessmentEmailsRequestSuccess,
    getRequestAssessmentsRequest,
    getRequestAssessmentsRequestFailed,
    getRequestAssessmentsRequestSuccess,
    getRequestLatestAssessmentRequest,
    getRequestLatestAssessmentRequestFailed,
    getRequestLatestAssessmentRequestSuccess,
    openStartAssessmentModal,
    proceedWithAvailableDataRequest,
    proceedWithAvailableDataRequestFailed,
    proceedWithAvailableDataRequestSuccess,
    sendAssessmentReminderEmailRequest,
    sendAssessmentReminderEmailRequestFailed,
    sendAssessmentReminderEmailRequestSuccess,
    startAssessmentRequest,
    startAssessmentRequestFailed,
    startAssessmentRequestSuccess,
} from '../actions/assessments.actions';
import { OrgService } from '@entities/org';
import { getRelationshipPopulatedLatestAssessment } from '../selectors/assessment.selectors';
import { MatConfirmDialogService } from '@shared/components/mat-confirm-dialog/mat-confirm-dialog.service';
import { MatDialogWrapperService } from '@shared/modal/mat-dialog-wrapper.service';

@Injectable()
export class AssessmentEffects {
    openStartAssessmentModal$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(openStartAssessmentModal),
                tap(({ request, primaryContact, recertification }) => {
                    this._dialogService.open<StartAssessmentComponent>(StartAssessmentComponent, {
                        inputs: {
                            requestId: request.id,
                            primaryVendorContact: primaryContact,
                            recertification,
                            clientId: request.clientId,
                            trustCenter: request.vendorTrustCenter,
                            thirdPartyOrgName: request.vendorName,
                        },
                    });
                }),
            ),
        { dispatch: false },
    );

    getRequestLatestAssessmentRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRequestLatestAssessmentRequest),
            switchMap(({ requestId }) =>
                this._relationshipService.getLatestAssessment(requestId).pipe(
                    map((latestAssessment) => getRequestLatestAssessmentRequestSuccess({ latestAssessment })),
                    catchError(() => of(getRequestLatestAssessmentRequestFailed())),
                ),
            ),
        ),
    );

    cancelRequestLatestAssessmentRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(cancelRequestLatestAssessmentRequest),
            switchMap(({ requestId }) =>
                this._relationshipService.cancelLatestAssessment(requestId).pipe(
                    map(() => cancelRequestLatestAssessmentRequestSuccess()),
                    catchError(() => of(cancelRequestLatestAssessmentRequestFailed())),
                ),
            ),
        ),
    );

    cancelRequestLatestAssessment$ = createEffect(() =>
        this._actions$.pipe(
            ofType(cancelRequestLatestAssessment),
            switchMap(({ requestId }) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Are you sure?',
                        message:
                            'Please confirm that you would like to cease all activity and cancel the assessment for this relationship.',
                        confirmLabel: 'Cancel assessment',
                        cancelLabel: 'Never mind',
                    })
                    .pipe(
                        first(),
                        filter(Boolean),
                        map(() => cancelRequestLatestAssessmentRequest({ requestId })),
                    ),
            ),
        ),
    );

    getRequestAssessmentsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRequestAssessmentsRequest),
            switchMap(({ requestId }) =>
                this._assessmentService.findAllByRelationshipId(requestId).pipe(
                    map((response) => response.body),
                    map((assessments) => assessments.sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime())),
                    map((assessments) => getRequestAssessmentsRequestSuccess({ assessments })),
                    catchError(() => of(getRequestAssessmentsRequestFailed())),
                ),
            ),
        ),
    );

    startAssessmentRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(startAssessmentRequest),
            switchMap(({ payload }) =>
                this._assessmentService.startAssessment(payload).pipe(
                    map(() => startAssessmentRequestSuccess({ email: payload.recipient?.email })),
                    catchError(() => of(startAssessmentRequestFailed())),
                ),
            ),
        ),
    );

    startAssessmentRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(startAssessmentRequestSuccess),
                withLatestFrom(this._store$.select(getUserProfileSelector)),
                tap(([{ email }, userProfile]) => {
                    if (userProfile?.showFirstAssessmentCreatedMessage) {
                        this._router.navigate(
                            [
                                {
                                    outlets: {
                                        popup: 'first-assessment-created',
                                    },
                                },
                            ],
                            {
                                replaceUrl: true,
                            },
                        );
                    } else {
                        const message = (email && `We sent an assessment request to: ${email}`) || 'Assessment Created';
                        this._snackbarService.success(message);
                    }
                }),
            ),
        { dispatch: false },
    );

    getOrgUsersRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getOrgUsersRequest),
            switchMap(({ orgId }) =>
                this._orgService.findUsersByOrgId(orgId).pipe(
                    map((users) => getOrgUsersRequestSuccess({ users })),
                    catchError(() => of(getOrgUsersRequestFailed())),
                ),
            ),
        ),
    );

    sendAssessmentReminderEmailRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(sendAssessmentReminderEmailRequest),
            switchMap(({ assessmentId }) =>
                this._assessmentService.sendReminderEmail(assessmentId).pipe(
                    map(() => sendAssessmentReminderEmailRequestSuccess({ assessmentId })),
                    catchError(() => of(sendAssessmentReminderEmailRequestFailed())),
                ),
            ),
        ),
    );

    sendAssessmentReminderEmailRequestSuccess$ = createEffect(
        () =>
            this._actions$.pipe(
                ofType(sendAssessmentReminderEmailRequestSuccess),
                withLatestFrom(this._store$.select(getRelationshipPopulatedLatestAssessment)),
                tap(([{ assessmentId }, { sentToEmail }]) => {
                    this._snackbarService.success(`We have sent a reminder email to ${sentToEmail}`);
                }),
            ),
        { dispatch: false },
    );

    confirmProceedWithAvailableData$ = createEffect(() =>
        this._actions$.pipe(
            ofType(confirmProceedWithAvailableData),
            switchMap(({ assessmentId }) =>
                this._confirmDialogService
                    .confirm({
                        title: 'Are you sure?',
                        message:
                            'If you proceed with available data, VISO TRUST will complete the assessment with artifacts that been provided about this relationship.',
                        confirmLabel: 'Proceed with available data',
                        confirmLabelIcon: 'fast_forward',
                    })
                    .pipe(
                        first(),
                        filter(Boolean),
                        map(() => proceedWithAvailableDataRequest({ assessmentId })),
                    ),
            ),
        ),
    );

    proceedWithAvailableDataRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(proceedWithAvailableDataRequest),
            switchMap(({ assessmentId }) =>
                this._assessmentService.markAsReviewStarted(assessmentId).pipe(
                    map(() => proceedWithAvailableDataRequestSuccess({ assessmentId })),
                    catchError(() => of(proceedWithAvailableDataRequestFailed())),
                ),
            ),
        ),
    );

    getRequestAssessmentEmailsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRequestAssessmentEmailsRequest),
            delay(500),
            switchMap(({ assessmentId }) =>
                this._assessmentService.getEmailsForAssessment(assessmentId).pipe(
                    map((emails) =>
                        emails.sort((a, b) => {
                            if (a.createdDate > b.createdDate) {
                                return -1;
                            } else if (a.createdDate < b.createdDate) {
                                return 1;
                            }
                            return 0;
                        }),
                    ),
                    map((emails) => getRequestAssessmentEmailsRequestSuccess({ assessmentId, emails })),
                    catchError(() => of(getRequestAssessmentEmailsRequestFailed())),
                ),
            ),
        ),
    );

    triggerGetRequestAssessmentEmailsRequest$ = createEffect(() =>
        this._actions$.pipe(
            ofType(getRequestLatestAssessmentRequestSuccess),
            map(({ latestAssessment }) => getRequestAssessmentEmailsRequest({ assessmentId: latestAssessment.id })),
        ),
    );

    triggerGetRequestAssessmentEmailsFromReminder$ = createEffect(() =>
        this._actions$.pipe(
            ofType(sendAssessmentReminderEmailRequestSuccess),
            map(({ assessmentId }) => getRequestAssessmentEmailsRequest({ assessmentId })),
        ),
    );

    constructor(
        private _router: Router,
        private _actions$: Actions,
        private _store$: Store,
        private _dialogService: MatDialogWrapperService,
        private _snackbarService: SnackbarService,
        private _relationshipService: RelationshipService,
        private _assessmentService: AssessmentService,
        private _orgService: OrgService,
        private _confirmDialogService: MatConfirmDialogService,
    ) {}
}
